import * as siteAccessLogging from 'site-access-logging';

/*
 * Resolve the global scope/namespace. It should be safe to always assume a browser runtime, 
 * which means the global object should always be "window". But, let's be over-prepared. 
 * Originally sourced from 
 * https://github.com/taylorhakes/promise-polyfill/blob/master/src/polyfill.js
 */
const globalScope = (function() {
  /*
   * The only reliable means to get the global object is `Function('return this')()`
   * However, this causes CSP violations in Chrome apps.
   */
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw new Error('unable to locate global object');
})();

/*
 * Define custom namespace to prevent pollution of the global scope.
 */
globalScope.mhsBlogNs = {};

/*
 * Populate custom namespace using an IIFE (Immediately Invoked Function Expression). More info: 
 *	https://stackoverflow.com/a/5947280/176247
 */
(function( mhsBlogNs ) {

/**
 * Bootstrap the app.
 */
mhsBlogNs.bootstrapApp = function() {

    siteAccessLogging.init({
    	//'stealthModeHostnamesArr': [],
    	'logTriggerUrl': '{{ global.logTriggerUrl }}',
      	'cloudflareToken': '{{ global.cloudflareToken }}', 
      	'forceCors': true
    });

	//siteAccessLogging.debug();

	siteAccessLogging.insertLogTrigger();
	siteAccessLogging.insertWebAnalytics();
	siteAccessLogging.displayStealthMode();
};

}(globalScope.mhsBlogNs));
